// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cbstt {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cbstt > .cbstt__title {
  display: flex;
  align-items: center;
  gap: 8px;
}
.cbstt > .cbstt__title > .new-tag {
  line-height: 1;
  margin: 0;
}
.cbstt > .cbstt__toggle {
  display: flex;
  align-items: center;
  gap: 5px;
}
.cbstt > .cbstt__toggle > span {
  font-size: 0.8rem;
  font-weight: 300;
}`, "",{"version":3,"sources":["webpack://./src/app/components/form/breakdown-custom-securities-toggle-title/styles.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;AACF;AACE;EACE,aAAA;EACA,mBAAA;EACA,QAAA;AACJ;AACI;EACE,cAAA;EACA,SAAA;AACN;AAGE;EACE,aAAA;EACA,mBAAA;EACA,QAAA;AADJ;AAGI;EACE,iBAAA;EACA,gBAAA;AADN","sourcesContent":[".cbstt {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n\n  & > .cbstt__title {\n    display: flex;\n    align-items: center;\n    gap: 8px;\n\n    & > .new-tag {\n      line-height: 1;\n      margin: 0;\n    }\n  }\n\n  & > .cbstt__toggle {\n    display: flex;\n    align-items: center;\n    gap: 5px;\n\n    & > span {\n      font-size: 0.8rem;\n      font-weight: 300;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
