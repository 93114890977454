// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.households-header {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  margin-bottom: 10px;
}
.households-header h2 {
  font-size: 2rem;
  line-height: normal;
  margin-bottom: 0;
  float: left;
}
.households-header .subtitle {
  font-size: 14px;
  margin: 0 9px;
}
.households-header .text-right {
  padding: 0;
}
.households-header .form-group.search {
  width: 230px;
}
.households-header .form-group.search {
  float: none;
  display: inline-block;
  margin: 0 0 0 12px;
}
.households-header .trash-icon {
  width: 18px;
  height: 20px;
  cursor: pointer;
}
.households-header .household-button {
  font-weight: 400;
}
.households-header .update-modal {
  z-index: 20;
}
.households-header .households-header__actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/advisor/households/list-header/styles.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,iBAAA;EACA,mBAAA;AACF;AACE;EACE,eAAA;EACA,mBAAA;EACA,gBAAA;EACA,WAAA;AACJ;AAEE;EACE,eAAA;EACA,aAAA;AAAJ;AAGE;EACE,UAAA;AADJ;AAIE;EACE,YAAA;AAFJ;AAKE;EACE,WAAA;EACA,qBAAA;EACA,kBAAA;AAHJ;AAME;EACE,WAAA;EACA,YAAA;EACA,eAAA;AAJJ;AAOE;EACE,gBAAA;AALJ;AAQE;EACE,WAAA;AANJ;AASE;EACE,aAAA;EACA,yBAAA;EACA,mBAAA;EACA,SAAA;AAPJ","sourcesContent":[".households-header {\n  display: flex;\n  justify-content: space-between;\n  padding-top: 20px;\n  margin-bottom: 10px;\n\n  h2 {\n    font-size: 2rem;\n    line-height: normal;\n    margin-bottom: 0;\n    float: left;\n  }\n\n  .subtitle {\n    font-size: 14px;\n    margin: 0 9px;\n  }\n\n  .text-right {\n    padding: 0;\n  }\n\n  .form-group.search {\n    width: 230px;\n  }\n\n  .form-group.search {\n    float: none;\n    display: inline-block;\n    margin: 0 0 0 12px;\n  }\n\n  .trash-icon {\n    width: 18px;\n    height: 20px;\n    cursor: pointer;\n  }\n\n  .household-button {\n    font-weight: 400;\n  }\n\n  .update-modal {\n    z-index: 20;\n  }\n\n  .households-header__actions {\n    display: flex;\n    justify-content: flex-end;\n    align-items: center;\n    gap: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
