import GeographicExposureDistribution from 'components/advisor/proposal/securities/geographic-exposure-distribution/pdf';
import { AdvisorContext } from 'containers/advisor';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import {
  hasSecurityDetails,
  SECURITY_SECTORS
} from 'components/advisor/risk-analysis/securities/common/utils';
import BaseSection from 'reports/base/sections/base';
import { MetadataPropTypes, ProposalPropTypes } from '../../types';

const ProposalGeographicExposure = ({ breakSection, metadata, proposal, proposalType }) => {
  const {
    user: {
      advisor: {
        company: { region_exposure_enabled: regionExposureEnabled }
      }
    }
  } = useContext(AdvisorContext);

  if (!regionExposureEnabled) return null;

  const breakdownCustomSecurities =
    metadata.customActions?.breakdownCustomSecurities?.value ?? true;

  if (!hasSecurityDetails(proposal.target, SECURITY_SECTORS, breakdownCustomSecurities))
    return null;

  return (
    <BaseSection body={metadata.body} title={metadata.title} breakSection={breakSection} wrap>
      <GeographicExposureDistribution
        benchmark={proposal.benchmark}
        benchmarkName={proposal.benchmark_label}
        breakdownCustomSecurities={breakdownCustomSecurities}
        proposal={proposal}
        proposalType={proposalType}
        recommended={proposal.recommended}
        recommendedName={proposal.recommended_label}
        start={proposal.start}
        startingValue={proposal.starting_value}
        target={proposal.target}
        targetName={proposal.target_label}
      />
    </BaseSection>
  );
};

ProposalGeographicExposure.propTypes = {
  breakSection: PropTypes.bool,
  metadata: PropTypes.shape(MetadataPropTypes).isRequired,
  proposal: PropTypes.shape(ProposalPropTypes).isRequired,
  proposalType: PropTypes.string.isRequired
};

ProposalGeographicExposure.defaultProps = {
  breakSection: false
};

export default ProposalGeographicExposure;
