// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#investment-style {
  display: flex;
  margin: 40px;
  gap: 65px;
}
#investment-style > p {
  flex-grow: 1;
  margin-bottom: 0;
  text-align: center;
}
#investment-style .chart-container > .morningstar-style-box-chart {
  margin-top: 10px;
}
#investment-style .collapsible-container {
  flex: 1;
}
#investment-style .collapsible-container .col-name,
#investment-style .collapsible-container .col-value,
#investment-style .collapsible-container .col-percentage {
  text-align: right;
}`, "",{"version":3,"sources":["webpack://./src/app/components/advisor/risk-analysis/securities/investment-style/styles.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,YAAA;EACA,SAAA;AADF;AAGE;EACE,YAAA;EACA,gBAAA;EACA,kBAAA;AADJ;AAIE;EACE,gBAAA;AAFJ;AAKE;EACE,OAAA;AAHJ;AAKI;;;EAGE,iBAAA;AAHN","sourcesContent":["@import 'assets/scss/commons/variables';\n\n#investment-style {\n  display: flex;\n  margin: 40px;\n  gap: 65px;\n\n  & > p {\n    flex-grow: 1;\n    margin-bottom: 0;\n    text-align: center;\n  }\n\n  .chart-container > .morningstar-style-box-chart {\n    margin-top: 10px;\n  }\n\n  .collapsible-container {\n    flex: 1;\n\n    .col-name,\n    .col-value,\n    .col-percentage {\n      text-align: right;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
