// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#investor-edit-modal tbody {
  background-color: white;
  border: none;
}
#investor-edit-modal label {
  margin-bottom: 0.5rem;
}`, "",{"version":3,"sources":["webpack://./src/app/components/advisor/investors/edit/styles.scss"],"names":[],"mappings":"AAGE;EACE,uBAAA;EACA,YAAA;AAFJ;AAKE;EACE,qBAAA;AAHJ","sourcesContent":["@import 'assets/scss/commons/variables';\n\n#investor-edit-modal {\n  tbody {\n    background-color: white;\n    border: none;\n  }\n\n  label {\n    margin-bottom: 0.5rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
