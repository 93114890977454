// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#MissingPrismModal .modal-dialog {
  margin-top: 124px;
}
#MissingPrismModal .modal-content {
  padding: 12px;
}
#MissingPrismModal .warning-icon {
  width: 90px;
  display: block;
  margin: 0 auto 30px auto;
}
#MissingPrismModal p {
  text-align: center;
  width: 83%;
  margin: 0 auto 16px auto;
}
#MissingPrismModal .modal-body {
  margin: 12px 0 26px 0;
}`, "",{"version":3,"sources":["webpack://./src/app/components/advisor/missing-prism-modal/styles.scss"],"names":[],"mappings":"AACE;EACE,iBAAA;AAAJ;AAGE;EACE,aAAA;AADJ;AAIE;EACE,WAAA;EACA,cAAA;EACA,wBAAA;AAFJ;AAKE;EACE,kBAAA;EACA,UAAA;EACA,wBAAA;AAHJ;AAME;EACE,qBAAA;AAJJ","sourcesContent":["#MissingPrismModal {\n  .modal-dialog {\n    margin-top: 124px;\n  }\n\n  .modal-content {\n    padding: 12px;\n  }\n\n  .warning-icon {\n    width: 90px;\n    display: block;\n    margin: 0 auto 30px auto;\n  }\n\n  p {\n    text-align: center;\n    width: 83%;\n    margin: 0 auto 16px auto;\n  }\n\n  .modal-body {\n    margin: 12px 0 26px 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
