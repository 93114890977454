// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#top-holdings {
  display: flex;
  margin: 40px;
}
#top-holdings > p {
  flex-grow: 1;
  margin-bottom: 0;
  text-align: center;
}
#top-holdings .collapsible-container {
  flex: 1;
}
#top-holdings .collapsible-container .col-name,
#top-holdings .collapsible-container .col-value,
#top-holdings .collapsible-container .col-percentage {
  text-align: right;
}`, "",{"version":3,"sources":["webpack://./src/app/components/advisor/risk-analysis/securities/top-holdings/styles.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,YAAA;AADF;AAGE;EACE,YAAA;EACA,gBAAA;EACA,kBAAA;AADJ;AAIE;EACE,OAAA;AAFJ;AAII;;;EAGE,iBAAA;AAFN","sourcesContent":["@import 'assets/scss/commons/variables';\n\n#top-holdings {\n  display: flex;\n  margin: 40px;\n\n  & > p {\n    flex-grow: 1;\n    margin-bottom: 0;\n    text-align: center;\n  }\n\n  .collapsible-container {\n    flex: 1;\n\n    .col-name,\n    .col-value,\n    .col-percentage {\n      text-align: right;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
