import GeographicExposureDistribution from 'components/advisor/proposal/securities/geographic-exposure-distribution/pdf';
import { AdvisorContext } from 'containers/advisor';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import {
  hasSecurityDetails,
  SECURITY_SECTORS
} from 'components/advisor/risk-analysis/securities/common/utils';
import BaseSection from 'reports/base/sections/base';
import { IPSPropTypes, MetadataPropTypes } from '../../types';

const IPSGeographicExposure = ({ breakSection, ips: { proposal }, metadata }) => {
  const {
    user: {
      advisor: {
        company: { region_exposure_enabled: regionExposureEnabled }
      }
    }
  } = useContext(AdvisorContext);

  if (!regionExposureEnabled) return null;

  const breakdownCustomSecurities =
    metadata.customActions?.breakdownCustomSecurities?.value ?? true;
  const distributionProps = { target: proposal.target, targetName: proposal.target_label };
  const hasRecommended = !!proposal.recommended;

  if (hasRecommended) {
    distributionProps.recommendedName = proposal.recommended_label;
    distributionProps.recommended = proposal.recommended;
  }

  if (!hasSecurityDetails(distributionProps.target, SECURITY_SECTORS, breakdownCustomSecurities))
    return null;

  return (
    <BaseSection body={metadata.body} title={metadata.title} breakSection={breakSection} wrap>
      <GeographicExposureDistribution
        breakdownCustomSecurities={breakdownCustomSecurities}
        proposal={proposal}
        {...distributionProps}
      />
    </BaseSection>
  );
};

IPSGeographicExposure.propTypes = {
  breakSection: PropTypes.bool,
  ips: PropTypes.shape(IPSPropTypes).isRequired,
  metadata: PropTypes.shape(MetadataPropTypes).isRequired
};

IPSGeographicExposure.defaultProps = {
  breakSection: false
};

export default IPSGeographicExposure;
